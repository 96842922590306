import React from 'react';
import Map from './components/Others/Map';
import AddButton from './components/Others/AddButton';
import CancelButton from './components/Others/CancelButton';

const App: React.FC = () => {
  const [dragMode, setDragMode] = React.useState(false);
  const handleAddButtonClick = () => {
    setDragMode(true);
  }
  const handleCancelButtonClick = () => {
    setDragMode(false);
  }

  return (
    <div className='App'>
      <Map dragMode={dragMode} />
      {!dragMode ? (
        <AddButton onClick={handleAddButtonClick} />
      ) : (
        <CancelButton onClick={handleCancelButtonClick} />
      )}

    </div>  
  );
};

export default App;
