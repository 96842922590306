import axios, {AxiosResponse} from "axios";

const axiosClient = axios.create({
    baseURL: "https://api.kanepo.app/api",
    });

axiosClient.interceptors.response.use(
    (response: AxiosResponse<any, any>) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosClient;