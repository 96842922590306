import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import audioApi from '../../api/audio';
import { AudioLocation } from '../../types';
import Ripple from '../Others/Ripple';

interface MarkerProps {
    map: mapboxgl.Map | null;
    locations: AudioLocation[];
    dragMode: boolean;
    setRoute: React.Dispatch<React.SetStateAction<[number, number][]>>;
}

interface AudioRipple {
    id: number;
    x: number;
    y: number;
    color: string;
}

const AudioMarkers: React.FC<MarkerProps> = ({map, locations, dragMode, setRoute}) => {
    const [playingAudio, setPlayingAudio] = useState<HTMLAudioElement | null>(null);
    const [markerElements, setMarkerElements] = useState<mapboxgl.Marker[]>([]);
    const [ripples, setRipples] = React.useState<AudioRipple[]>([]);
    const markersPassed = useRef<AudioLocation[]>([]);
    const handleDragModeRef = useRef<((location: AudioLocation) => void)>();
    let nextId = 0;

    const fadeOutAudio = (audio: HTMLAudioElement) => {
        const fadeOutInterval = 50;
        const fadeOutDuration = 1000;
        const fadeStep = audio.volume / (fadeOutDuration / fadeOutInterval);

        const fadeOut = setInterval(() => {
            if (audio.volume > 0.05) {
                audio.volume = Math.max(audio.volume - fadeStep, 0);
            } else {
                audio.pause();
                audio.volume = 1;
                clearInterval(fadeOut);
            }
        } , fadeOutInterval);
    };

    useEffect(() => {
        handleDragModeRef.current = (location: AudioLocation) => {
            if(dragMode && map) {
                markersPassed.current.push(location);
            }
        }
    }, [dragMode, map]);

     useEffect(() => {
        if (!map) return
        locations.forEach(location => {
            const el = document.createElement('div');
            el.className = 'audioMarker bg-transparent rounded-full w-3 h-3 position-absolute';
            el.setAttribute('data-latlng', `${location.latitude},${location.longitude}`);
            el.setAttribute('data-id', location._id);
            el.style.border = '2px solid transparent';
            

            const marker = new mapboxgl.Marker(el, { anchor: 'center' })
                .setLngLat([location.longitude, location.latitude])
                .addTo(map);
            
            marker.getElement().addEventListener('click', async () => {
                try {
                    if (playingAudio) {
                        fadeOutAudio(playingAudio);
                        setPlayingAudio(null);
                    } else {
                        const audioData = await audioApi.fetchAudioStreamById(location.file_id);
                        const audioBlob = new Blob([audioData.data], {type: 'audio/mpeg'});
                        const audioUrl = URL.createObjectURL(audioBlob);
                        const audio = new Audio(audioUrl);
                        audio.play();
                        setPlayingAudio(audio);
                    }
                } catch (error) {
                    console.log(error);
                }
            });
            marker.getElement().addEventListener('mouseenter', () => {
                if (handleDragModeRef.current) {
                    handleDragModeRef.current(location);
                }
            });
            setMarkerElements(prev => [...prev, marker]);
        });
    }, [map, locations, playingAudio, nextId]);

    useEffect(() => {
        if(!map){
            return;
        }

        const randomRipples = () => {
            const markerCounts: {[key: string]: number} = {};
                markersPassed.current.forEach((marker: AudioLocation) => {
                    if (markerCounts[marker._id]) {
                        markerCounts[marker._id]++;
                    } else {
                        markerCounts[marker._id] = 1;
                    }
                });
            const topTenMarkers = Object.entries(markerCounts).sort((a, b) => b[1] - a[1]).slice(0, 10).map(([_id]) => _id);
            const randomMarkerIndex = Math.floor(Math.random() * topTenMarkers.length);
            const randomMarkerId = topTenMarkers[randomMarkerIndex];
            const randomMarker = markerElements.find(marker => marker.getElement().getAttribute('data-id') === randomMarkerId);
            const markerLngLat = randomMarker!.getLngLat();
            const point = map.project([markerLngLat.lng, markerLngLat.lat]);
            const mapContainer = map.getContainer().getBoundingClientRect();
            const markerX = mapContainer.left + point.x;
            const markerY = mapContainer.top + point.y;
            const audioRipple = {
                id: nextId++,
                x: markerX,
                y: markerY,
                color: "white"
            };

            setRipples(prev => [...prev, audioRipple]);

            setTimeout(() => {
                setRipples(prev => prev.filter(ripple => ripple.id !== audioRipple.id));
            }, 1000);
        }


        if(dragMode){
            markerElements.forEach(marker => {
                marker.getElement().classList.remove('bg-blue-500');
                marker.getElement().classList.add('bg-transparent');
                marker.getElement().style.border = '2px solid transparent';
            });
            markersPassed.current = [];
            setRoute([]);
        } else {
            
            if (markersPassed.current.length > 0) {
                const markerCounts: {[key: string]: number} = {};
                markersPassed.current.forEach((marker: AudioLocation) => {
                    if (markerCounts[marker._id]) {
                        markerCounts[marker._id]++;
                    } else {
                        markerCounts[marker._id] = 1;
                    }
                });
                const topTenMarkers = Object.entries(markerCounts).sort((a, b) => b[1] - a[1]).slice(0, 10).map(([_id]) => _id);
                const routeCoordinates: [number, number][] = [];
                markerElements.forEach(marker => {
                    if (topTenMarkers.includes(marker.getElement().getAttribute('data-id')!)) {
                        marker.getElement().classList.remove('bg-transparent');
                        marker.getElement().classList.add('bg-red-500');
                        marker.getElement().style.border = '2px solid white';
                        const [latitude, longitude] = marker.getElement().getAttribute('data-latlng')!.split(',').map(parseFloat);
                        routeCoordinates.push([longitude, latitude]);
                    }
                });
                setRoute(routeCoordinates);
                const rippleInterval = setInterval(randomRipples, 1000);
                return () => clearInterval(rippleInterval);
            }
        }
    }, [dragMode]);
    
    return (
        <>
            {ripples.map(ripple => (
                <Ripple x={ripple.x} y={ripple.y} color={ripple.color} width={150} height={150} key={ripple.id} />
            ))}
        </>
    )
}
export default AudioMarkers;