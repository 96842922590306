import React from 'react';
import { motion } from 'framer-motion';

const BlackOverlay: React.FC = () => {
    return (
        <motion.div
            id="canvasOverlay"
            className="absolute top-0 left-0 w-full h-full bg-black opacity-100 pointer-events-none"
            style={{zIndex: 5}}
            initial={{ opacity: 0 }} 
            animate={{ opacity: 1 }} 
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }} 
        />
    );
};

export default BlackOverlay;
