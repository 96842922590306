import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import { ScentLocation } from '../../types';
import ScentRipple from './ScentRipple';
import * as turf from '@turf/turf';

interface MarkerProps {
    map: mapboxgl.Map | null;
    locations: ScentLocation[];
    dragMode: boolean;
    route: [number, number][];
}

interface ScentRippleProps {
    id: number;
    x: number;
    y: number;
    color1: string;
    color2: string;
}


const ScentMarkers: React.FC<MarkerProps> = ({map, locations, dragMode, route}) => {
    const [ripples, setRipples] = useState<ScentRippleProps[]>([]);
    const [markerElements, setMarkerElements] = useState<mapboxgl.Marker[]>([]);
    const handleDragModeRef = useRef<((location: ScentLocation) => void)>();
    let nextId: number = 0;

    useEffect(() => {
        handleDragModeRef.current = (location: ScentLocation) => {
            if (dragMode && map){
                const point = map.project([location.longitude, location.latitude]);
                const mapContainer = map.getContainer().getBoundingClientRect();

                const markerX = mapContainer.left + point.x;
                const markerY = mapContainer.top + point.y;

                const scentRipple = {
                    id: nextId++,
                    x: markerX,
                    y: markerY,
                    color1: location.color1,
                    color2: location.color2,
                };

                setRipples(prev => [...prev, scentRipple]);
                

                setTimeout(() => {
                    setRipples(prev => prev.filter(ripple => ripple.id !== scentRipple.id));
                }, 1000);
            }
        };
    }, [dragMode]);


    useEffect(() => {
        if (!map) return
         
        locations.forEach(location => {
            const el = document.createElement('div');
            el.className = 'scentMarker rounded-full position-absolute';
            el.style.backgroundColor = location.color1;
            el.style.border = `2px solid ${location.color2}`;
            el.style.width = '10px';
            el.style.height = '10px';
            el.setAttribute('data-color1', location.color1);
            el.setAttribute('data-color2', location.color2);

            const marker = new mapboxgl.Marker(el)
                .setLngLat([location.longitude, location.latitude])
                .setPopup(
                    new mapboxgl.Popup({ offset: 25 })
                        .setHTML(
                            `<h3>${location.name}</h3>`
                        )
                )
                .addTo(map);
            marker.getElement().addEventListener('mouseenter', () => {
                if (handleDragModeRef.current) {
                    handleDragModeRef.current(location);
                }
            });
            setMarkerElements(prev => [...prev, marker]);
        });
    }, [map, locations, nextId]);

    useEffect(() => {
        if (!map) return;
    
        const createRandomRipple = () => {
            if (route.length > 1 && route.length <= 25) {
                const proximityThreshold = 75; // Proximity threshold in meters
                const routeLine = turf.lineString(route);
    
                const eligibleMarkers = markerElements.filter(marker => {
                    const markerLngLat = marker.getLngLat();
                    const markerPoint = turf.point([markerLngLat.lng, markerLngLat.lat]);
                    const distance = turf.pointToLineDistance(markerPoint, routeLine, { units: 'meters' });
    
                    return distance < proximityThreshold;
                });
    
                if (eligibleMarkers.length > 0) {
                    // Select a random marker from eligible markers
                    const randomMarkerIndex = Math.floor(Math.random() * eligibleMarkers.length);
                    const randomMarker = eligibleMarkers[randomMarkerIndex];
                    const markerLngLat = randomMarker.getLngLat();
    
                    // Calculate marker position on the screen
                    const point = map.project([markerLngLat.lng, markerLngLat.lat]);
                    const mapContainer = map.getContainer().getBoundingClientRect();
                    const markerX = mapContainer.left + point.x;
                    const markerY = mapContainer.top + point.y;
    
                    const color1 = randomMarker.getElement().getAttribute('data-color1')!;
                    const color2 = randomMarker.getElement().getAttribute('data-color2')!;
    
                    // Create the ripple effect
                    const scentRipple = {
                        id: nextId++,
                        x: markerX,
                        y: markerY,
                        color1: color1,
                        color2: color2,
                    };
    
                    setRipples(prev => [...prev, scentRipple]);
    
                    // Remove the ripple after 1 second
                    setTimeout(() => {
                        setRipples(prev => prev.filter(ripple => ripple.id !== scentRipple.id));
                    }, 1000);
                }
            }
        };
    
        if (dragMode) {
            markerElements.forEach(marker => {
                marker.getElement().style.width = '15px';
                marker.getElement().style.height = '15px';
                marker.getElement().style.backgroundColor = 'transparent';
                marker.getElement().style.border = '2px solid transparent';
            });
        } else {
            markerElements.forEach(marker => {
                marker.getElement().style.width = '10px';
                marker.getElement().style.height = '10px';
            });
            if (route.length > 1 && route.length <= 25) {
                const proximityThreshold = 75
                const routeLine = turf.lineString(route);
                markerElements.forEach(marker => {
                    const markerLngLat = marker.getLngLat();
                    const markerPoint = turf.point([markerLngLat.lng, markerLngLat.lat]);
                    const distance = turf.pointToLineDistance(markerPoint, routeLine, {units: 'meters'});
                    if (distance < proximityThreshold) {
                        const color1 = marker.getElement().getAttribute('data-color1')!;
                        const color2 = marker.getElement().getAttribute('data-color2')!;
                        marker.getElement().style.width = '10px';
                        marker.getElement().style.height = '10px';
                        marker.getElement().style.backgroundColor = `${color1}`;
                        marker.getElement().style.border = `2px solid ${color2}`;
                    }
                });
            }
            // Start a loop to create random ripples every 500ms
            const rippleInterval = setInterval(createRandomRipple, 1000);
    
            // Clean up the interval on component unmount or when dependencies change
            return () => clearInterval(rippleInterval);
        }
    }, [dragMode, route]);
    return (
        <>
            {ripples.map(ripple => (
                <ScentRipple key={ripple.id} x={ripple.x} y={ripple.y} color1={ripple.color1} color2={ripple.color2} />
            ))}
        </>
    )
}

export default ScentMarkers;
