import React from "react";
import IconButton from "./IconButton";
import { PlusOutlined } from "@ant-design/icons";

interface AddButtonProps {
    onClick: () => void;
}

const AddButton: React.FC<AddButtonProps> = ({ onClick }) => {
    return (
        <IconButton 
            icon={<PlusOutlined />} 
            onClick={onClick}
            bgColor="bg-blue-500"
            hoverColor="hover:bg-blue-600"
        />
    );
};

export default AddButton;