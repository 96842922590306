import React, { useEffect, useRef, useState } from "react";
import { AudioGridRes } from "../../types";
import Ripple from "../Others/Ripple";
import audioApi from "../../api/audio";

interface GridProps {
    map: mapboxgl.Map | null;
    grids: AudioGridRes[];
    dragMode: boolean;
}

interface AudioRipple {
    id: number;
    x: number;
    y: number;
    color: string;
}

interface GridWithAudio extends AudioGridRes {
    assignedAudioId: string;
    audioUrl: string;
}

const AudioGrid: React.FC<GridProps> = ({ map, grids, dragMode }) => {
    const [ripples, setRipples] = useState<AudioRipple[]>([]);
    const [audioPlaying, setAudioPlaying] = useState<HTMLAudioElement | null>(null);
    const [currentAudioUrl, setCurrentAudioUrl] = useState<string | null>(null);
    const nextId = useRef(0);
    const lastRippleTime = useRef(0);
    const [gridsWithAudio, setGridsWithAudio] = useState<GridWithAudio[]>([]);
    const fadeInAudio = (audio: HTMLAudioElement) => {
        const fadeInInterval = 50; 
        const fadeInDuration = 1000; 
        const fadeStep = 1 / (fadeInDuration / fadeInInterval); 
    
        audio.volume = 0;
        audio.play();
    
        const fadeIn = setInterval(() => {
            if (audio.volume < 0.95) {
                audio.volume = Math.min(audio.volume + fadeStep, 1); 
            } else {
                audio.volume = 1; 
                clearInterval(fadeIn);
            }
        }, fadeInInterval);
    };
    const fadeOutAudio = (audio: HTMLAudioElement) => {
        const fadeOutInterval = 50;
        const fadeOutDuration = 1000;
        const fadeStep = audio.volume / (fadeOutDuration / fadeOutInterval);

        const fadeOut = setInterval(() => {
            if (audio.volume > 0.05) {
                audio.volume = Math.max(audio.volume - fadeStep, 0);
            } else {
                audio.pause();
                audio.volume = 1;
                clearInterval(fadeOut);
            }
        } , fadeOutInterval);
    };
    useEffect(() => {
        const assignAudioToGrids = async () => {
            const assignedGrids = await Promise.all(
                grids.map(async grid => {
                    const assignedAudioId = grid.file_ids[Math.floor(Math.random() * grid.file_ids.length)];
                    const audioResponse = await audioApi.fetchAudioStreamById(assignedAudioId);
                    const audioBlob = new Blob([audioResponse.data], { type: "audio/mpeg" });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    return { ...grid, assignedAudioId, audioUrl };
                })
            );
            setGridsWithAudio(assignedGrids);
            console.log(assignedGrids);
        };

        assignAudioToGrids();
    }, [grids]);

    useEffect(() => {
        if (dragMode && map) {
            const handleMouseMove = (e: mapboxgl.MapMouseEvent & mapboxgl.MapMouseEvent) => {
                const currentTime = Date.now();
                const rippleDelay = 400; // Delay between ripples in milliseconds
                let isInsideGrid = false;

                if (currentTime - lastRippleTime.current >= rippleDelay) {
                    const mouseLng = e.lngLat.lng;
                    const mouseLat = e.lngLat.lat;

                    gridsWithAudio.forEach(grid => {
                        const { corners, audioUrl } = grid;

                        // Check if the mouse is within the bounds of the polygon
                        const withinLongitude = mouseLng >= corners.southwest.longitude && mouseLng <= corners.northeast.longitude;
                        const withinLatitude = mouseLat >= corners.southwest.latitude && mouseLat <= corners.northeast.latitude;

                        if (withinLongitude && withinLatitude) {
                            isInsideGrid = true;
                            const mapContainer = map.getContainer().getBoundingClientRect();
                            const mouseX = e.point.x + mapContainer.left;
                            const mouseY = e.point.y + mapContainer.top;

                            const rippleEffect: AudioRipple = {
                                id: nextId.current++,
                                x: mouseX,
                                y: mouseY,
                                color: "white"
                            };

                            setRipples(prev => [...prev, rippleEffect]);
                            
                            if (currentAudioUrl !== audioUrl) {
                                if (audioPlaying) {
                                    fadeOutAudio(audioPlaying);
                                    setAudioPlaying(null);
                                }

                                const newAudio = new Audio(audioUrl);
                                fadeInAudio(newAudio)
                                setAudioPlaying(newAudio);
                                setCurrentAudioUrl(audioUrl);
                            }

                            // Update the last ripple time
                            lastRippleTime.current = currentTime;

                            // Remove the ripple after some time
                            setTimeout(() => {
                                setRipples(prev => prev.filter(ripple => ripple.id !== rippleEffect.id));
                            }, 1000);
                        }
                    });

                    if (!isInsideGrid && audioPlaying) {
                        fadeOutAudio(audioPlaying);
                        setAudioPlaying(null);
                        setCurrentAudioUrl(null);
                    }
                }
            };

            map.on('mousemove', handleMouseMove);

            return () => {
                map.off('mousemove', handleMouseMove);
            };
        }
    }, [dragMode, map, gridsWithAudio, audioPlaying, currentAudioUrl]);

    return (
        <>
            {ripples.map(ripple => (
                <Ripple x={ripple.x} y={ripple.y} color={ripple.color} width={150} height={150} key={ripple.id} />
            ))}
        </>
    );
}

export default AudioGrid;