import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

interface RippleProps {
    x: number;
    y: number;
    color: string;
    width: number;
    height: number;
}

const Ripple: React.FC<RippleProps> = ({ x, y, color, width, height }) => {
    const [doneAnimation, setDoneAnimation] = useState(false);
    const controls = useAnimation();
    useEffect(() => {
        const startAnimation = async () => {
            await controls.start({
                width: width,
                height: height,
                left: x - width/2,
                top: y - height/2,
                opacity: 0,
                transition: {
                    duration: 1,
                },
            });

            setDoneAnimation(true);
        };

        startAnimation();
    }, [controls, x, y]);

    if (doneAnimation) {
        return null;
    }

    return (
        <motion.div
            style={{
                position: 'absolute',
                left: x,
                top: y,
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: 'transparent',
                border: `3px solid ${color}`,
                zIndex: 99999,
            }}
            animate={controls}
            initial={{width: 0, height: 0, left: x, top: y, opacity: 1}}
        />
    );
}

export default Ripple;
            