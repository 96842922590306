import React from "react";

interface IconButtonProps {
    icon: React.ReactNode;
    onClick?: () => void;
    size?: number;
    bgColor?: string;
    borderColor?: string;
    hoverColor?: string;
    zIndex?: number;
}

const IconButton: React.FC<IconButtonProps> = ({
    icon,
    onClick,
    size = 24,
    bgColor = 'bg-blue-500',
    hoverColor = 'hover:bg-blue-600',
    borderColor = 'border-blue-500',
    zIndex = 2
}) => {
    return (
        <button
            onClick={onClick}
            className={`rounded-full ${bgColor} ${hoverColor} ${borderColor} w-10 h-10 p-2 left-1/2 bottom-4 absolute `}
            style={{zIndex: zIndex}}
        >
            {icon}
        </button>
    );
}

export default IconButton;