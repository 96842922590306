import React, {useState, useEffect} from 'react';
import Ripple from '../Others/Ripple'

interface ScentRippleProps {
    x: number;
    y: number;
    color1: string;
    color2: string;
}

const ScentRipple: React.FC<ScentRippleProps> = ({x, y, color1, color2}) => {
    const [secondRipple, setSecondRipple] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setSecondRipple(true);
        }, 400);
    }, []);

    return (
        <>
            <Ripple x={x} y={y} color={color1} width={100} height={100} />
            {secondRipple && <Ripple x={x} y={y} color={color2} width={100} height={100} />}
        </>

    );
}

export default ScentRipple;