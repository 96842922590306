import React from "react";
import IconButton from "./IconButton";
import { CloseOutlined } from "@ant-design/icons";

interface CancelButtonProps {
    onClick: () => void;
}   

const CancelButton: React.FC<CancelButtonProps> = ({ onClick }) => {
    return (
        <IconButton 
            icon={<CloseOutlined />} 
            onClick={onClick}
            bgColor="bg-red-500"
            hoverColor="hover:bg-red-600"
            zIndex={20}
        />
    );
};

export default CancelButton;