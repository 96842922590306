import React, {useRef, useEffect, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import audioApi from '../../api/audio';
import scentApi from '../../api/scent';
import AudioMarkers from '../Audio/AudioMarkers';
import ScentMarkers from '../Scent/ScentMarkers';
import { AudioLocation, ScentLocation, AudioGridRes } from '../../types';
import BlackOverlay from './BlackOverlay';
import { AnimatePresence } from 'framer-motion';
import gridApi from '../../api/grid';
import AudioGrid from '../Audio/AudioGrid';

mapboxgl.accessToken = "pk.eyJ1IjoieXVrZXN0YXIiLCJhIjoiY2xqM3Q1Y2ZwMGMwczNlcDJwNTd5OHRrdiJ9.kg3esDQxWi1tCMs8nWFNcw";

interface MapProps {
    dragMode: boolean;
}


const Map: React.FC<MapProps> = ({ dragMode }) => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const [route, setRoute] = useState<[number, number][]>([]);
    const map = useRef<mapboxgl.Map | null>(null);
    const ini_lat = 35.388170520212746;
    const ini_lng = 139.4272849266398;

    const [audioLoc, setAudioLoc] = React.useState<AudioLocation[]>([]);
    useEffect(() => {
        audioApi.fetchAllAudioLoc()
            .then(response => {
                setAudioLoc(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const [scentLoc, setScentLoc] = React.useState<ScentLocation[]>([]);
    useEffect(() => {
        scentApi.fetchAllScentLoc()
            .then(response => {
                setScentLoc(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const [grid, setGrid] = React.useState<AudioGridRes[]>([]);
    useEffect(() => {
        gridApi.fetchAllSuperGrids()
            .then(response => {
                setGrid(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/yukestar/clj3t92k100ss01r7d42vhkxh',
                center: [ini_lng, ini_lat], 
                zoom: 14,
                bearing: 0,
                pitch: 0,
                dragRotate: false,
                touchZoomRotate: false,
                pitchWithRotate: false,
            });

        }
    }, []);

    useEffect(() => {
        if (map.current) {
            if (dragMode) {
                map.current.dragPan.disable();
                map.current.scrollZoom.disable();
                map.current.doubleClickZoom.disable();
                map.current.boxZoom.disable();
                setRoute([]);
                if (map.current.getLayer('route')) {
                    map.current.removeLayer('route');
                }
                if (map.current.getSource('route')) {
                    map.current.removeSource('route');
                }
                
            } else {
                map.current.dragPan.enable();
                map.current.scrollZoom.enable();
                map.current.doubleClickZoom.enable();
                map.current.boxZoom.enable();
            }
        }
    }, [dragMode]);

    useEffect(() => {
        if (route.length > 1 && route.length <= 25) {
            const coordinates = route.map(point => point.join(',')).join(';');
            const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapboxgl.accessToken}`;
            fetch(url)
                .then(response => response.json())
                .then(data => {

                    if (map.current) {
                        map.current.addSource('route', {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                properties: {},
                                geometry: data.routes[0].geometry,
                            },
                        });
                        map.current.addLayer({
                            id: 'route',
                            type: 'line',
                            source: 'route',
                            layout: {
                                'line-join': 'round',
                                'line-cap': 'round',
                            },
                            paint: {
                                'line-color': 'white',
                                'line-width': 5,
                                'line-opacity': 0.75,
                            },
                        });
                    }
                });
        }
    }, [route]);

    return (
        <div ref={mapContainer} id='map' style={{width: '100%', height: '100vh', zIndex: '1'}} >
            
            <AudioMarkers map={map.current} locations={audioLoc} dragMode={dragMode} setRoute={setRoute}/>
            <ScentMarkers map={map.current} locations={scentLoc} dragMode={dragMode} route={route}/>
            <AudioGrid map={map.current} grids={grid} dragMode={dragMode}/> 
            <AnimatePresence>
                {dragMode && (
                    <BlackOverlay />
                )}
            </AnimatePresence>
        </div>
    );
};

export default Map;